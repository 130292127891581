/**
 * START Polyfills
 */
import dynamic from 'next/dynamic'

dynamic(
  () =>
    import('@juggle/resize-observer').then((module) => {
      window.ResizeObserver = module.ResizeObserver
    }),
  { ssr: false }
)
dynamic(() => import('raf/polyfill'), { ssr: false })

// core-js comes with Next.js. So, you can import it like below
dynamic(() => import('core-js/es/map'), { ssr: false })
dynamic(() => import('core-js/es/weak-map'), { ssr: false })
dynamic(() => import('core-js/es/set'), { ssr: false })
dynamic(() => import('core-js/es/symbol'), { ssr: false })
dynamic(() => import('core-js/es/array/find-index'), { ssr: false })
dynamic(() => import('core-js/es/array/includes'), { ssr: false })
dynamic(() => import('core-js/es/promise'), { ssr: false })

dynamic(() => import('formdata-polyfill'), { ssr: false })

dynamic(() => import('picturefill'), { ssr: false })
dynamic(() => import('picturefill/dist/plugins/mutation/pf.mutation'), {
  ssr: false,
})

dynamic(
  () =>
    import('smoothscroll-polyfill').then((module) => {
      module.polyfill()
    }),
  { ssr: false }
)
/**
 * END Polyfills
 */

import * as React from 'react'
import App from 'next/app'
import Router from 'next/router'
import getConfig from 'next/config'
import withFBQ from 'next-fbq'
import { SWRConfig } from 'swr'
import { parseCookies } from 'nookies'

import { withAuthContext } from 'lib/authContext'
import withResponsiveContext from 'lib/withResponsiveContext'
import withAnnouncements from 'lib/withAnnouncements'
import withLightBox from 'lib/withLightBox'
import withModal from 'lib/withModal'
import withModalStack from 'lib/withModalStack'
import withTooltip from 'lib/withTooltip'
import withCountry from 'lib/withCountry'
import withSearch from 'lib/searchContext'
import withWouter from 'lib/withWouter'

import DefaultLayout from 'components/DashboardLayout'
import { getAuthenticatedClient } from 'utils/api'
import { compose } from 'utils/misc'
import { getDefaultSize } from 'utils/responsive'

import 'utils/hamburgers.css'

const {
  publicRuntimeConfig: { fbPixelId },
} = getConfig()

type Props = {
  Component: React.ComponentType<*>,
  pageProps: Object,

  country: string,
  authToken: ?string,
}

function ShipperApp(props: Props) {
  const { Component, pageProps, authToken } = props
  const Layout = Component.layout || DefaultLayout
  const pageTitle = Component.title

  return (
    <SWRConfig
      value={{
        fetcher: (url) => {
          const client = getAuthenticatedClient(authToken)
          return client.get(url).then((res) => res.data)
        },
      }}
    >
      <Layout title={pageTitle}>
        <Component authToken={authToken} {...pageProps} />
      </Layout>
    </SWRConfig>
  )
}

ShipperApp.getInitialProps = async (appContext) => {
  const initialProps = await App.getInitialProps(appContext)

  if (appContext.ctx.req) {
    const mobileDetect = require('../utils/mobileDetect')
    initialProps.defaultWindowSize = getDefaultSize(
      mobileDetect(appContext.ctx.req.headers)
    )

    const cookies = parseCookies(appContext.ctx)
    if (cookies && cookies.authToken) {
      initialProps.authToken = cookies.authToken
    }
  }

  return { ...initialProps }
}

export default compose(
  withFBQ(fbPixelId, Router),
  withWouter,
  withResponsiveContext,
  withAuthContext,
  withCountry,
  withAnnouncements,
  withLightBox,
  withModalStack,
  withModal,
  withTooltip,
  withSearch
)(ShipperApp)
