export default {
  ru: {
    addBalancePage: {
      title: 'Пополнить баланс',
      amountField: {
        label: 'Введите сумму',
        labelWithValue: 'К оплате',
        suggestedAmount: 'Готово к выдаче посылок на {amount}'
      },
      payWithCardButtonLabel: 'К оплате',
      payWithCardSubmitButtonLabel: 'Оплатить',

      paymentInProcess: 'Оплачиваем...',
      paymentSucceeded: 'Баланс пополнен на {amount}',
      goBackToParcels: 'Вернуться к посылкам',
      tryAgain: 'Попробовать ещё раз',
      contactSupport: 'Обратиться в поддержку'
    }
  }
}
