import css from 'styled-jsx/css'

export function hexToRgbA(hex, alpha) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    )
  }
  throw new Error('Bad Hex')
}

const baseColors = {
  white: '#ffffff',
  blue: '#455AC6',
  darkerBlue: '#2C40AD',
  darkBlue: '#2A1B38',
  lightBlue: '#636279',
  overlap: '#451F68',
  red: '#F03855',
  lightRed: '#FDDFE5',
  pink: '#ff5786',
  gray: '#948d9b',
  grayText: '#57607B',
  lightGray: '#ededed',
  lightGraySecondary: '#A2B0BA',
}

const UIColors = {
  blueText: '#455AC6',
  blueLink: '#2f82ff',
  inputText: '#A3B1BB',
  inputPlaceholder: '#9CA9BA',
  inputBorder: '#E0E8ED',
  inputBorderFocus: '#B8C9D5',
  buttonHover: '#ff4d68',
  buttonActive: '#e22844',
  buttonDisabled: '#A3B1BB',
  faqBg: '#F1F6F9',
  faqStroke: '#E0E8ED',
  faqHeader: '#2A1B38',
  footerPageLink: '#9CA9BA',
  dashboardFooterText: '#6A738B',
  dashboardDarkBlue: '#14145A',
  dashboardLightGray: '#A2B0BA',
  dashboardStroke: 'rgba(88, 94, 138, 0.2)',
  dashboardPink: '#F93B58',
  deliveryStatus: {
    new: {
      background: 'rgba(87, 96, 123, 0.1)',
      label: '#57607B',
    },
    executing: {
      background: '#FFF0DC',
      label: '#FBB357',
    },
    prepaymentInvoice: {
      background: '#D9E6FE',
      label: '#4C89FB',
    },
    packaging: {
      background: '#4C89FB',
      label: '#D9E6FE',
    },
    finalInvoice: {
      background: '#D0F1F0',
      label: '#21B9B3',
    },
    onDelivery: {
      background: '#FBB357',
      label: '#FFF0DC',
    },
    won: {
      background: '#21B9B3',
      label: '#D0F1F0',
    },
    lose: {
      background: '#FDDFE5',
      label: '#F93B58',
    },
  },
  grayBorder: '#EDEEF2',
}

const DarkUIColors = {
  background: '#202124',
  parcels: {
    listCardBackground: '#2E2F31',
    headerBorder: '#292A2D',
  },
}

export const colors = {
  ...baseColors,
  ...UIColors,
  dark: DarkUIColors,
}

export const media = {
  tablet: 768,
  desktop: 1024,
  wide: 1130,
}

export const fonts = {
  IBMPlex: "'IBM Plex Sans', sans-serif",
}

export const transition = {
  timingFunction: 'cubic-bezier(.23, 1, .32, 1)',
}

export const sizes = {
  containerWidth: 1200,
  containerMinWidth: 320,
  containerPadding: 20,
  containerTabletPadding: 20,
  containerMobilePadding: 20,
  footerHeight: 210,
  gap: 30,
}

export const globalStyle = css.global`
  html {
    height: 100%;
    height: -webkit-fill-available;
    box-sizing: border-box;
    /* Make page always scrollable to eliminate route change jumping */
    overflow-y: scroll;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
    min-height: 100%;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    font: 400 20px/30px 'IBM Plex Sans', sans-serif;
    background-color: #fff;
    color: ${colors.dashboardDarkBlue};
  }

  .container {
    width: 100%;
    max-width: ${sizes.containerWidth}px;
    margin: 0 auto;
    padding: 0 ${sizes.containerMobilePadding}px;
  }

  a {
    color: ${colors.blue};
  }

  input,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${colors.inputText};
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.03) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  hr {
    height: 1px;
    border: 0;

    background: ${colors.faqStroke};
  }

  @media (min-width: ${media.tablet}px) {
    .container {
      padding: 0 ${sizes.containerTabletPadding}px;
    }
  }

  @media (min-width: ${media.desktop}px) {
    .container {
      padding: 0 ${sizes.containerPadding}px;
    }
  }
`

export const buttonStyle = css`
  .button {
    display: block;
    width: 100%;
    padding: 17px 20px;
    border: 1px solid ${colors.red};
    border-radius: 4px;
    overflow: hidden;

    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-family: ${fonts.IBMPlex};
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;

    color: #fff;
    background: ${colors.red};
    outline: none;

    transition: box-shadow 0.9s ${transition.timingFunction};

    cursor: pointer;
  }

  .button:hover {
    box-shadow: 0px 11px 34px rgba(216, 30, 59, 0.3);
  }

  .button:active,
  .button:focus {
    border-color: ${colors.buttonActive};
    background-color: ${colors.buttonActive};
  }

  .button:disabled,
  .button.disabled {
    border-color: ${colors.buttonDisabled};
    background-color: ${colors.buttonDisabled};
    box-shadow: none;
  }

  .button.outline {
    border-color: ${colors.inputText};
    background-color: transparent;
    color: ${colors.inputText};
  }

  .button.outline:disabled {
    background-color: transparent;
    opacity: 0.6;
  }

  .button.outline:hover,
  .button.outline:active,
  .button.outline:focus {
    border-color: ${colors.grayText};
    background-color: transparent;
    color: ${colors.grayText};
  }

  .button.outline:hover {
    box-shadow: none;
  }

  @media (min-width: ${media.desktop}px) {
    .button {
      padding: 18px 20px 21px;
    }
  }
`

const BUTTON_HEIGHT = 40
const BUTTON_BORDER_SIZE = 1
const BUTTON_BORDER_RADIUS = 4

export const bottomSheetButtonStyle = css`
  .button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button-group .button {
    flex: 1;
  }

  .button-group .button:not(:last-child) {
    margin-right: 16px;
  }

  .button,
  .button-placeholder {
    display: block;

    width: 100%;
    height: ${BUTTON_HEIGHT}px;
    padding: 0;

    text-decoration: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-family: ${fonts.IBMPlex};
    font-size: 16px;
    font-weight: 600;
    line-height: ${BUTTON_HEIGHT - BUTTON_BORDER_SIZE * 2}px;

    user-select: none;

    color: ${colors.dashboardDarkBlue};
  }

  .button {
    border: ${BUTTON_BORDER_SIZE}px solid #eeeff2;
    border-radius: ${BUTTON_BORDER_RADIUS}px;

    background: #eeeff2;
    transition: box-shadow 0.3s ${transition.timingFunction};

    outline: #eeeff2;
  }

  .button:not(:disabled) {
    cursor: pointer;
  }

  .button.secondary:not(:disabled):hover {
    background: #e5e6ea;
    border-color: #e5e6ea;
  }

  .button.outlined {
    border-color: ${colors.inputText};
    background-color: transparent;
    color: ${colors.inputText};
  }

  .button.outlined:not(:disabled):hover {
    border-color: #57607b;
    color: #57607b;
  }

  .button.primary {
    background: #f93b58;
    border-color: #f93b58;
    color: ${colors.white};
  }
  .button.primary:not(:disabled):hover {
    background: #ff4d68;
    border-color: #ff4d68;
    box-shadow: 0px 11px 34px rgba(216, 30, 59, 0.3);
  }
  .button.primary:not(:disabled):active {
    background: #e22844;
    border-color: #e22844;
  }

  .button.primary:disabled {
    border-color: ${colors.buttonDisabled};
    background-color: ${colors.buttonDisabled};
    box-shadow: none;
  }

  .button.complete {
    background: none;
  }
`

export const springConfig = {
  mass: 0.5,
  tension: 300,
  friction: 8,
  velocity: 100,
}
