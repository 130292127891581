import Responsive from 'react-responsive'

import { media } from './style'

export const breakPoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200
}

export const defaultSizes = {
  // the phone value covers portrait and landscape - there's no way to tell the
  // difference from the request unless we have client hints (which don't work
  // on the first request anyway) or something similar
  phone: breakPoints.sm - 1,
  // this is tricky too - we're going by what bootstrap uses as a default but
  // an ipad in portrait mode will match here even though the width might be
  // 1024, 1112, or 1366. for now leave as is - in the future we could handle
  // this by compiling a list of resolutions but that's a huge undertaking.
  tablet: breakPoints.md - 1,
  // this comes from bootstrap lg which we use this as our default desktop size
  // (even though technically > 991 is desktop most seem to fall here).
  desktop: breakPoints.lg
}

export const getDefaultSize = ({ phone, tablet, mobile, desktop }) => {
  let fakeWidth

  if (mobile) {
    if (phone) {
      fakeWidth = defaultSizes.phone
    } else if (tablet) {
      fakeWidth = defaultSizes.tablet
    } else {
      // TODO - should we ever get here? default to the lowest value i guess
      fakeWidth = defaultSizes.phone
    }
  } else if (desktop) {
    fakeWidth = defaultSizes.desktop
  } else {
    // nothing set, default to our defaultSize
    fakeWidth = defaultSizes.desktop
  }

  return fakeWidth
}

export const Mobile = props => (
  <Responsive {...props} maxWidth={media.desktop} />
)

export const Tablet = props => (
  <Responsive {...props} minWidth={media.tablet} maxWidth={media.desktop} />
)

export const Desktop = props => (
  <Responsive {...props} minWidth={media.desktop + 1} />
)
