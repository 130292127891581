export default {
  ru: {
    intro: {
      lead: {
        title: 'Быстрая доставка товаров из США',
        description:
          'Доверьте нам доставку ваших покупок из США. Чтобы получить адрес в США, зарегистрируйтесь на сайте. Добавляйте номера отправлений покупок в личном кабинете и ожидайте их скорого прибытия в {countryName}. Делаем доставку быстро, надежно и с заботой о посылке.'
      },
      buttonBlock: {
        title: 'Получить свой адрес в США — просто!',
        action: 'Получить адрес'
      }
    },
    simpleSteps: {
      title: 'Как заказать доставку?',
      description: 'Выполните три простых шага, чтобы получить покупку из США',
      stepLabel: {
        title: 'Шаг'
      }
    },
    delivery: {
      title: 'О доставке'
    },
    searchItem: {
      title: 'Не знаете, где продают то, что вам нужно?',
      description:
        'Расскажите подробно о товаре, который хотите заказать в США. Мы сами найдем продавца, сообщим стоимость и доставим в {countryName}.',
      form: {
        fields: {
          description: 'Описание товара',
          email: 'Ваш email'
        },
        actions: {
          submit: 'Найти и доставить',
          submitting: 'Отправка...'
        },
        annotation: 'Указывая email вы принимаете ',
        agreement: 'Условия соглашения'
      },
      success: {
        title: 'Запрос получен!',
        description: 'Как мы найдем ваш товар — мы ответим вам незамедлительно!'
      }
    },
    calculate: {
      title: 'Рассчитайте стоимость доставки',
      description:
        'Стоимость доставки – {koeff} долларов за килограмм. Чтобы вы не переплачивали, мы округляем расчет веса до 100 грамм. В списке можно выбрать популярные товары и узнать примерную стоимость их доставки, а на калькуляторе рассчитать точно по весу.',
      form: {
        modes: {
          product: 'По товару',
          weight: 'По весу'
        },
        cost: 'Стоимость доставки',
        time: 'Срок доставки',
        days: '{count} дней',
        annotation: '*точный вес посылки определяется на складе'
      }
    },
    examples: {
      title: 'Чаще всего заказывают в США',
      inLocalCountry: 'В {countryName}',
      inUSA: 'В США'
    },
    instagram: {
      title:
        'Следи за инсайдами от <a href="https://www.instagram.com/{accountName}/" target="_blank" rel="noopener noreferrer">@{accountName}</a>',
      description:
        'В Instagram мы рассказываем, как и где покупать в США, информируем о сезонных и незапланированных распродажах в магазинах, скидываем выгодные лоты, а также делимся другим полезным контентом.'
    },
    footer: {
      logoDescription: 'Сервис доставки посылок<br />из США',
      allRightsReserved: 'Все права защищены',
      register: 'Получить адрес',
      pages: {
        agreement: 'Соглашение с пользователем',
        privacy: 'Политика конфиденциальности'
      },
      contacts: {
        phone: 'Телефон',
        email: 'Email',
        support: 'Поддержка'
      }
    }
  }
}
