// @flow
import ReactGA from 'react-ga'
import ym from 'react-yandex-metrika'
import getConfig from 'next/config'

import * as store from './store'
import { updateUser } from './api'

const {
  publicRuntimeConfig: { upStage }
} = getConfig()

const prodStage = ['production', 'prod'].includes(upStage) 

export async function manageSourceParameter(utmSource: string, token: ?string) {
  const isAuthenticated = Boolean(token)

  if (isAuthenticated) {
    let userData

    try {
      userData = await store.get('authUserData')
    } catch (error) {
      console.log(error)
    }

    if (userData && typeof userData.id === 'number' && token) {
      await updateUser(token, userData.id, { utm_source: utmSource })
    }
    await store.clear('utmSource')
  } else {
    await store.set('utmSource', utmSource)
  }
}

export function setUserId(id: string, yandexAccountId?: string): void {
  ReactGA.set({ userId: id })

  if (prodStage && yandexAccountId) {
    ym(yandexAccountId, 'setUserID', id)
  }
}

export function hitgoal(category: string, action: string): void {
  ReactGA.event({
    category,
    action
  })
  if (prodStage) {
    ym('reachGoal', category, { action })
  }
}
