// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

import { colors, fonts } from '../utils/style'

type TooltipOptions = {
  timeout: number
}

type TooltipType = {
  targetRef: any,
  content: React.Node,
  options: TooltipOptions
}

type TooltipsValueType = Array<TooltipType>

type TooltipContextValue = {
  tooltips: TooltipsValueType,
  register: (
    targetRef: any,
    content: React.Node,
    options?: TooltipOptions
  ) => void,
  unregister: (targetRef: any) => void
}

type VisibleTooltip = {
  targetNode: HTMLElement,
  content: React.Node,
  x: number,
  y: number
}

const defaultContextValue: TooltipContextValue = {
  tooltips: [],
  register: () => {},
  unregister: () => {}
}

const defaultTooltipOptions = {
  timeout: 1000
}

export const TooltipContext: React.Context<TooltipContextValue> = React.createContext(
  defaultContextValue
)

export default function TooltipHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function tooltipHOC(props: Object) {
    const tooltipRef = React.useRef()
    const [tooltips, setTooltips] = React.useState<TooltipsValueType>([])
    const [visibleTooltip, setVisibleTooltip] = React.useState<?VisibleTooltip>(
      null
    )

    function register(
      targetRef: any,
      content: React.Node,
      options?: TooltipOptions = {}
    ) {
      setTooltips([
        ...tooltips,
        {
          targetRef,
          content,
          options: { ...defaultTooltipOptions, ...options }
        }
      ])
    }

    function unregister(targetRef: any) {
      setTooltips(
        tooltips.filter(
          tooltip => tooltip.targetRef.current !== targetRef.current
        )
      )
    }

    React.useEffect(() => {
      function handleMouseOver(event: any) {
        const targetNode = event.target

        tooltips.forEach(tooltip => {
          const tooltipTargetEl = tooltip.targetRef.current
          if (tooltipTargetEl && tooltipTargetEl.contains(targetNode)) {
            const { x, y, height } = tooltipTargetEl.getBoundingClientRect()

            setVisibleTooltip({
              targetNode: tooltipTargetEl,
              content: tooltip.content,
              x: x - 60,
              y: y + height
            })
          }
        })
      }

      function handleMouseOut(event: any) {
        let relatedTarget = event.relatedTarget
        const tooltipEl = tooltipRef.current

        if (!visibleTooltip) return

        if (tooltipEl) {
          if (
            relatedTarget === tooltipEl ||
            tooltipEl.contains(relatedTarget) ||
            visibleTooltip.targetNode.contains(relatedTarget)
          )
            return

          setVisibleTooltip(null)
        }
      }

      document.addEventListener('mouseover', handleMouseOver, { passive: true })
      document.addEventListener('mouseout', handleMouseOut, { passive: true })

      return function cleanup() {
        document.removeEventListener('mouseover', handleMouseOver)
        document.removeEventListener('mouseout', handleMouseOut)
      }
    }, [tooltips, visibleTooltip])

    return (
      <React.Fragment>
        <TooltipContext.Provider value={{ register, unregister, tooltips }}>
          <WrappedComponent {...props} />
        </TooltipContext.Provider>
        {visibleTooltip ? (
          <div
            ref={tooltipRef}
            className="tooltip"
            style={{ left: visibleTooltip.x, top: visibleTooltip.y }}
          >
            {/* $FlowFixMe */}
            {typeof visibleTooltip.content === 'function'
              ? visibleTooltip.content()
              : visibleTooltip.content}
          </div>
        ) : null}

        <style jsx>{`
          .tooltip {
            position: absolute;
            z-index: 600;

            padding: 19px 16px;
            max-width: 330px;
            border: 1px solid #f6f6f8;
            box-sizing: border-box;
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            font-family: ${fonts.IBMPlex};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;

            background: ${colors.white};
            color: ${colors.inputText};
          }
        `}</style>
      </React.Fragment>
    )
  }

  hoistNonReactStatics(tooltipHOC, WrappedComponent)
  return tooltipHOC
}
