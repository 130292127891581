export default {
  ru: {
    authHeader: {
      login: 'Вход',
      registration: 'Регистрация'
    },
    authSecondaryActions: {
      alreadyRegistered: 'Есть учетная запись?',
      haveNoAccount: 'Нет учетной записи?',
      login: 'Войти',
      registration: 'Регистрация',
      forgotPassword: 'Восстановление пароля'
    },
    loginPage: {
      title: 'Вход',
      form: {
        email: 'Email',
        password: 'Пароль',
        rememberMe: 'Запомнить меня',
        submit: 'Войти',
        submitting: 'Вход...'
      }
    },
    registrationPage: {
      title: 'Регистрация',
      form: {
        firstName: 'Имя',
        lastName: 'Фамилия',
        phone: 'Телефон',
        email: 'Почта',
        password: 'Пароль',
        passwordConfirmation: 'Повторите пароль',
        submit: 'Зарегистрироваться',
        submitting: 'Регистрация...'
      },
      mobile: {
        back: 'Назад',
        next: 'Далее'
      },
      termsNotice:
        'Нажимая «Зарегистрироваться», вы подтверждаете, что прочитали Политику конфиденциальности и согласны с Условиями оказания услуг'
    },
    forgotPasswordPage: {
      title: 'Забыли пароль?',
      form: {
        email: 'Email',
        submit: 'Отправить',
        submitting: 'Отправка...'
      },
      success: {
        title: 'Готово!',
        description: 'Мы отправили новый пароль на вашу почту!'
      }
    }
  }
}
