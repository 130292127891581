export default {
  ru: {
    dashboardPage: {
      title: 'Ваш персональный адрес в Америке',
      description:
        'Это адреса складов в Америке, которые вам нужно указывать при оформлении покупок в американских интернет-магазинах',
      helpLinkLabel: 'Примеры заполнения адреса',
    },
    dashboardShops: {
      pageTitle: 'Магазины',
      description:
        'Более 200 продавцов и миллионы товаров, которые не продаются в {countryName}. Выбирайте и экономьте до 80% на покупках за счет скидок и распродаж.',
      defaultSectionTitle: 'Все',
      linkLabel: 'Перейти в магазин',
    },
    dashboardHelp: {
      pageTitle: 'Помощь',
      defaultWidgetsTitle: 'Обратите внимание',
    },
    dashboardSearch: {
      inputPlaceholder: 'Поиск посылок',
    },
    dashboardUserMenu: {
      greeting: 'Hello,',
      menuItems: {
        settings: 'Настройки',
        logout: 'Выйти',
      },
    },
    dashboardFooter: {
      copyright: 'Shipper © {year} Все права защищены',
      nav: {
        privacy: 'Политика конфиденциальности',
        agreement: 'Соглашение с пользователем',
        status: 'Статус',
      },
    },
    dashboardNav: {
      add: 'Добавить',
      addParcels: 'Добавить посылку',
      parcelItems: {
        allLinkLabel: 'Посылки',
        all: 'Посылки',
        new: 'Ожидаем',
        prepaymentInvoice: 'Получили',
        packaging: 'На упаковке',
        executing: 'Отправили',
        finalInvoice: 'Готовы к выдаче',
        onDelivery: 'На доставке',
        won: 'Доставлены',
      },
      balance: {
        label: 'Баланс',
      },
      userItems: {
        address: 'Мой адрес в США',
        calculator: 'Стоимость доставки',
        help: 'Помощь',
        settings: 'Настройки',
      },
    },
    warehouses: {
      annotations: {
        taxFree: 'Нет налога на покупки',
        openingSoon: 'Скоро открытие'
      },
      addressCard: {
        firstName: 'First name',
        lastName: 'Last name',
        firstLine: 'Street address',
        secondLine: 'Address 2 (Apt, Unit, Suite)',
        city: 'City',
        state: 'State',
        zip: 'ZIP',
        phone: 'Phone',
        country: 'Country',
      },
      addressCardTooltip: {
        line2:
          'Address 2 – это ваш идентификатор на складе, указывайте обязательно, так мы узнаем, что посылка именно ваша. Дополнительное поле в интернет-магазине может называться Address 2, Optional, Unit ',
      },
      clipboardCopyMessage: 'Скопировано',
    },
    dashboardParcelsPage: {
      title: 'Все посылки',
      emptyForStatus: {
        default: {
          title: 'Ждёте посылку? Зарегистрируйте её.',
          description: 'Добавьте номер отслеживания (tracking number) ожидаемой посылки, чтобы знать, когда она поступит на ',
          addressesLink: 'склад.',
        },
        rest: {
          title: 'Список посылок пуст',
          description: 'Посылок со статусом «{statusName}» не найдено',
        },
      },
    },
    dashboardParcels: {
      item: {
        id: 'Номер',
        date: 'Дата',
        description: 'Содержимое',
        trackNumber: 'Трекинг номер',
        weight: 'Вес',
        price: 'Цена',
        pay: 'Оплатить',
        topUp: 'Пополнить',
        address: 'Адрес получателя',
        insureFor: 'Застраховать за',
        insure: 'Застраховать',
        insuredSuccess: 'Успешно застраховано',
        insured: 'Застраховано',
        subscribe: 'Подписка',
        dimensions: 'Размер посылки',
        payParcel: 'Оплатить доставку',
        notEnoughFunds: 'Недостаточно средств',
        payed: 'Оплачено',
        payedSuccess: 'Успешно оплачено',
        kg: 'кг',
        labelPhoto: 'Фото лейбла',
        parcelPhoto: 'Фото посылки',
        deliveryFromUSA: 'Доставка из США',
        deliveryInsurance: 'Страховка посылки',
        trackCTA: 'Отследить посылку'
      },
      status: {
        new: 'Ожидаем',
        prepaymentInvoice: 'Получили',
        packaging: 'На упаковке',
        executing: 'Отправили',
        finalInvoice: 'Готова к выдаче',
        onDelivery: 'На доставке',
        won: 'Доставлен',
        lose: 'Отменен',
      },
      dropdownNav: {
        delete: 'Удалить',
      },
      payInsurance: {
        title: 'Страховка посылки',
        description: 'Страховка от неполучения или порчи. При страховом случае, мы выплатим полную стоимость товара.',
        priceLabel: 'Стоимость страховки',
        annotation: '{basePercentage}% от стоимости содержимого (минимум {minAmount})'
      },
      track: {
        title: 'Трекинг посылки'
      }
    },
    addParcelForm: {
      title: 'Новая посылка',
      form: {
        description: 'Содержимое посылки',
        price: 'Цена',
        descriptionPlaceholder: 'Например: Кроссовки – Nike',
        trackingNumber: 'Трекинг номер',
        trackingNumberPlaceholder: '9405509699937388402754',
        recipient: 'Получатель',
        address: 'Адрес получателя',
        tin: 'ИИН',
        passportFullname: 'ФИО',
        personalDataDetailsLabel: 'Зачем нам эти данные?',
        cancel: 'Отмена',
        back: 'Назад',
        next: 'Далее',
        submit: 'Добавить',
        submitting: 'Добавление...',
        additionalServices: {
          title: 'Дополнительные услуги',
          repackage: 'Перепаковать',
          repackageType: 'Бесплатно',
          repackageDescription: 'Позволяет экономить на доставке до 30%',
        },
      },
      description:
        'Укажите номер отслеживания вашей покупки. Обычно он присваивается в момент ее отправки из магазина. Это может занять до нескольких дней с момента совершения покупки',
      helpLinkLabel: 'Где найти трекинг номер?',
    },
    calculatorPage: {
      title: 'Стоимость доставки',
      description:
        'В списке можно выбрать популярные товары и узнать примерную стоимость их доставки.',
    },
    deleteParcel: {
      deleteMsg: 'Вы уверены, что хотите удалить посылку?',
      deleteSuccess: 'Посылка удалена',
    },
  },
}
