import LocalizedStrings from 'react-localization'
import merge from 'lodash/merge'

import Auth from './i18n/Auth'
import Dashboard from './i18n/Dashboard'
import AddBalance from './i18n/AddBalance'
import UserSettings from './i18n/UserSettings'
import Validation from './i18n/Validation'
import Calculator from './i18n/Calculator'
import Home from './i18n/Home'
import Common from './i18n/Common'

const strings = new LocalizedStrings(
  merge(
    Auth,
    Dashboard,
    AddBalance,
    UserSettings,
    Validation,
    Calculator,
    Home,
    Common
  ),
  'ru'
)

export default strings
