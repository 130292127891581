export default {
  ru: {
    common: {
      edit: 'Редактировать',
      continue: 'Дальше',
      save: 'Сохранить',
      saving: 'Сохраняем',
      cancel: 'Отмена',
      saved: 'Сохранено',
      delete: 'Удалить',
      deleting: 'Удаляем',
      total: 'Итого',
    },
  },
}
