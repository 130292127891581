// @flow
import * as React from 'react'
import Link from 'next/link'

import { colors, media } from '../utils/style'
import strings from '../localization'

export default function DashboardFooter() {
  const [year] = React.useState((new Date()).getFullYear())
  return (
    <div className="root">
      <div className="container">
        <span>{strings.formatString(strings.dashboardFooter.copyright, { year })}</span>

        <Link href="https://shipper.kz/privacy">
          <a>{strings.dashboardFooter.nav.privacy}</a>
        </Link>
        <Link href="https://shipper.kz/agreement">
          <a>{strings.dashboardFooter.nav.agreement}</a>
        </Link>
        <a href="https://shipper.statuspage.io/" target="_blank" rel="noopener noreferrer">
          {strings.dashboardFooter.nav.status}</a>
      </div>

      <style jsx>{`
        .root {
          display: none;
          padding: 67px 0 24px;
          font-size: 16px;
          line-height: 24px;

          color: ${colors.dashboardFooterText};
        }

        .container {
          display: flex;
          justify-content: flex-end;
        }

        span {
          margin-right: auto;
        }

        a {
          margin-left: 38px;
          color: ${colors.dashboardFooterText};
        }

        @media (min-width: ${media.desktop}px) {
          .root {
            display: block;
          }
        }
      `}</style>
    </div>
  )
}
