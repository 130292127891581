// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import isAfter from 'date-fns/isAfter'
import axios from 'axios'

import * as store from '../utils/store'
import { useCountry } from './withCountry'

export type Announcement = {
  id: string,
  description: string,
  title?: string,
  endsAt?: string,
}

type ContextValue = {
  announcements: Array<Announcement>,
  ignoreAnnouncementById: (id: string) => Promise<*>,
}

const defaultContextValue = {
  announcements: [],
  ignoreAnnouncementById: async () => {},
}

export const AnnouncementsContext =
  React.createContext<ContextValue>(defaultContextValue)

type Props = Object

export default function AnnouncementsContextHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function announcementsContextHOC(props: Props) {
    const [announcements, setAnnouncements] = React.useState([])
    const { country } = useCountry()

    React.useEffect(() => {
      async function loadAnnouncements() {
        const { data: announcementsData } = await axios.get('/api/announcements')

        const ignoredAnnouncementIds = await store.get('ignoredAnnouncementIds')

        if (Array.isArray(announcementsData) && announcementsData.length > 0) {
          const nextAnnouncements = announcementsData.filter(
            (announcement) => {
              if (!['ALL', 'WEB'].includes(announcement.platform)) return false

              if (!['ALL', country.toUpperCase()].includes(announcement.region)) return false

              // endsAt must be in ISO format https://en.wikipedia.org/wiki/ISO_8601
              // Example: 2019-12-26
              const endsAt =
                typeof announcement.endsAt === 'string'
                  ? parseISO(announcement.endsAt)
                  : undefined

              if (endsAt && isValid(endsAt) && isAfter(new Date(), endsAt)) {
                return false
              }

              if (
                Array.isArray(ignoredAnnouncementIds) &&
                ignoredAnnouncementIds.indexOf(announcement.id) !== -1
              ) {
                return false
              }

              return true
            }
          )
          setAnnouncements(nextAnnouncements)
        }
      }

      if (country) {
        loadAnnouncements()
      }
    }, [country])

    const ignoreAnnouncementById = React.useCallback(
      async (id: string) => {
        setAnnouncements(
          announcements.filter((announcement) => announcement.id !== id)
        )
        // eslint-disable-next-line
        const ignoredAnnouncementIds = await store.get('ignoredAnnouncementIds')
        const nextIgnoredAnnouncementIds = Array.isArray(ignoredAnnouncementIds)
          ? [...ignoredAnnouncementIds, id]
          : [id]
        await store.set('ignoredAnnouncementIds', nextIgnoredAnnouncementIds)
      },
      [announcements]
    )

    const contextValue = React.useMemo(
      () => ({ announcements, ignoreAnnouncementById }),
      [announcements, ignoreAnnouncementById]
    )

    return (
      <AnnouncementsContext.Provider value={contextValue}>
        <WrappedComponent {...props} />
      </AnnouncementsContext.Provider>
    )
  }

  hoistNonReactStatics(announcementsContextHOC, WrappedComponent)
  return announcementsContextHOC
}

export function useAnnouncements() {
  const { announcements, ignoreAnnouncementById } =
    React.useContext(AnnouncementsContext)

  return [announcements, ignoreAnnouncementById]
}
