// @flow
import * as React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import WithAnalytics from 'components/WithAnalytics'
import MetrikaTracker from 'components/MetrikaTracker'
import SvgSprite from 'components/SvgSprite'
import DashboardFooter from 'components/DashboardFooter'

import AuthContext from 'lib/authContext'
import { globalStyle, colors } from 'utils/style'
import { compose } from 'utils/misc'

import { PROTECTED_ROUTES } from '../constants'

function getTitle(title, defaultTitle, tail) {
  if (title) {
    return `${title} — ${tail}`
  }

  return defaultTitle
}

type Props = {
  children: React.Node,
  title?: string
}

function DashboardLayout({ children, title }: Props) {
  const authContext = React.useContext(AuthContext)
  const router = useRouter()

  React.useEffect(() => {
    if (
      PROTECTED_ROUTES.includes(router.route) &&
      authContext.isRehydrated &&
      !authContext.token
    ) {
      router.push('/')
    }
  }, [router, authContext])

  return (
    <div className="root">
      <Head>
        <title>
          {getTitle(
            title,
            'Shipper – сервис доставки посылок из Америки',
            'Shipper'
          )}
        </title>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#455ac6"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        <meta
          name="title"
          content="Shipper – сервис доставки посылок из Америки"
        />
        <meta
          name="description"
          content="Надежно, быстро, просто, экономно! 13 долларов за 1 кг., округление до 100 г., в среднем от 7 дней."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://shipper.kz/" />
        <meta
          property="og:title"
          content="Shipper – сервис доставки посылок из Америки"
        />
        <meta
          property="og:description"
          content="Надежно, быстро, просто, экономно! 13 долларов за 1 кг., округление до 100 г., в среднем от 7 дней."
        />
        <meta
          property="og:image"
          content="https://shipper.kz/shipper-og.jpg"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://shipper.kz/" />
        <meta
          property="twitter:title"
          content="Shipper – сервис доставки посылок из Америки"
        />
        <meta
          property="twitter:description"
          content="Надежно, быстро, просто, экономно! 13 долларов за 1 кг., округление до 100 г., в среднем от 7 дней."
        />
        <meta
          property="twitter:image"
          content="https://shipper.kz/shipper-og.jpg"
        />
      </Head>

      <SvgSprite />

      <div className="main">{children}</div>

      <DashboardFooter />

      <MetrikaTracker />

      <style jsx global>
        {globalStyle}
      </style>
      <style jsx global>{`
        body {
          background-color: ${colors.faqBg};
        }

        h2 {
          color: ${colors.dashboardDarkBlue};
        }
      `}</style>
      <style jsx>{`
        .root {
          display: flex;
          position: relative;
          min-height: 100vh;
          flex-direction: column;
        }

        .main {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </div>
  )
}

export default compose(WithAnalytics)(DashboardLayout)
