// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import useKey from 'use-key-hook'

import LightBox from 'components/LightBox'

export type Images = Array<{ src: string, label: string }>

type LightBoxContextValue = {
  open: (images: Images, index?: number) => void,
  close: () => void,
}

const defaultContextValue = {
  open: () => {},
  close: () => {},
}

export const LightBoxContext: React.Context<LightBoxContextValue> = React.createContext(
  defaultContextValue
)

type Props = Object

export default function LightBoxContextHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function lightBoxContextHOC(props: Props) {
    const [index, setIndex] = React.useState(0)
    const [images, setImages] = React.useState(null)

    const open = React.useCallback(
      (nextImages, initialIndex) => {
        setIndex(initialIndex ?? 0)
        setImages(nextImages)
      },
      []
    )

    const close = React.useCallback(() => {
      setImages(null)
      setIndex(0)
    }, [])

    useKey(close, { detectKeys: [27] })

    const contextValue = React.useMemo(() => ({ open, close }), [open, close])

    return (
      <>
        <LightBoxContext.Provider value={contextValue}>
          <WrappedComponent {...props} />
        </LightBoxContext.Provider>
        {Array.isArray(images) && images.length > 0 ? (
          <LightBox
            items={images}
            index={index}
            setPhotoIndex={setIndex}
            onClose={close}
          />
        ) : null}
      </>
    )
  }

  hoistNonReactStatics(lightBoxContextHOC, WrappedComponent)
  return lightBoxContextHOC
}

export function useLightBox() {
  const { open, close } = React.useContext(LightBoxContext)

  return { open, close }
}
