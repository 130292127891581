// @flow
export function compose(...funcs: Array<Function>) {
  if (funcs.length === 0) {
    // infer the argument type so it is usable in inference down the line
    return (arg: any) => arg
  }
  if (funcs.length === 1) {
    return funcs[0]
  }
  return funcs.reduce((a, b) => (...args) => a(b(...args)))
}

export function composeRegex(
  regexes: Array<RegExp>,
  flags: string = 'igm'
): RegExp {
  return new RegExp(
    `(${regexes.map((reg) => reg.toString().slice(1, -1)).join(')|(')})`,
    flags
  )
}

export const combineReducers = (slices: Object) => (
  state: Object,
  action: Object
) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  )

export function roundFloat(value: number, decimalPoints?: number = 2): string {
  return value.toFixed(decimalPoints)
}

export function generateRandomUUIDString(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}
