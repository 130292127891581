// @flow
import * as React from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'
import Router from 'next/router'
import debounce from 'lodash/debounce'

type SearchContextValue = {
  searchQuery: string,
  setSearchQuery: (nextSearchQuery: string) => void
}

const defaultValue: SearchContextValue = {
  searchQuery: '',
  setSearchQuery: () => {}
}

export const SearchContext: React.Context<SearchContextValue> = React.createContext(
  defaultValue
)

const PARCELS_ROUTE = '/parcels'

export default function withSearch(WrappedComponent: React.ComponentType<*>) {
  function ComponentWithSearch(props: Object) {
    const {
      router: { route }
    } = props
    const [searchQuery, setSearchQuery] = React.useState('')

    const changeCallback = debounce(() => {
      if (route !== PARCELS_ROUTE) {
        Router.push(PARCELS_ROUTE)

        if (typeof document !== 'undefined') {
          const searchInput = document.getElementById('dashboard-search-input')
          if (searchInput) {
            searchInput.focus()
          }
        }
      }
    }, 1000)

    function handleSetSearchQuery(nextSearchQuery: string) {
      setSearchQuery(nextSearchQuery)

      changeCallback()
    }

    const contextValue: SearchContextValue = {
      searchQuery: searchQuery,
      setSearchQuery: handleSetSearchQuery
    }

    return (
      <SearchContext.Provider value={contextValue}>
        <WrappedComponent {...props} />
      </SearchContext.Provider>
    )
  }

  hoistNonReactStatic(ComponentWithSearch, WrappedComponent)
  return ComponentWithSearch
}
