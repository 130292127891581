// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

import AuthContext from './authContext'
import { COUNTRIES_BY_REGION_ID } from 'utils/api'

import { COUNTRY_NAMES } from '../constants'

export const DEFAULT_COUNTRY = 'kz'

type CountryType = 'kg' | 'kz'

type ContextValue = {
  country: CountryType,
}

const defaultContextValue = {
  country: DEFAULT_COUNTRY,
}

export const CountryContext = React.createContext<ContextValue>(
  defaultContextValue
)

function getOrDetectCountry(authContext) {
  if (authContext?.userData) {
    return (
      COUNTRIES_BY_REGION_ID[authContext.userData.regionId] || DEFAULT_COUNTRY
    )
  } else if (typeof document !== 'undefined' && typeof document.referrer === 'string') {
    return document.referrer.match(/\.kg/gim) ? 'kg' : 'kz'
  }

  return DEFAULT_COUNTRY
}

type Props = {
  country: CountryType,
}

export default function CountryContextHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function countryContextHOC(props: Props) {
    const authContext = React.useContext(AuthContext)

    const [currentCountry, setCountry] = React.useState(
      getOrDetectCountry(authContext)
    )

    React.useEffect(() => {
      setCountry(getOrDetectCountry(authContext))
    }, [authContext])

    const contextValue = React.useMemo(() => ({ country: currentCountry }), [
      currentCountry,
    ])

    return (
      <CountryContext.Provider value={contextValue}>
        <WrappedComponent {...props} />
      </CountryContext.Provider>
    )
  }

  hoistNonReactStatics(countryContextHOC, WrappedComponent)
  return countryContextHOC
}

export function useCountry() {
  const { country } = React.useContext(CountryContext)
  const countryConfig = COUNTRY_NAMES[country] || COUNTRY_NAMES[DEFAULT_COUNTRY]
  const countryName = countryConfig.default
  const countryNameGenitive = countryConfig.genitive

  return { country, countryName, countryNameGenitive }
}
