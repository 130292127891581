// @flow
import * as React from 'react'
import ym, { YMInitializer } from 'react-yandex-metrika'
import Router from 'next/router'
import getConfig from 'next/config'

import { CountryContext } from 'lib/withCountry'
import { YANDEX_METRIKA_ID } from '../constants'

const {
  publicRuntimeConfig: { upStage },
} = getConfig()

const prodStage = ['production', 'prod'].includes(upStage)

type Props = {}

export default class MetrikaTracker extends React.Component<Props> {
  lastTrackedPath: ?string

  static contextType = CountryContext

  componentDidMount() {
    this.trackPageview()
    Router.router.events.on('routeChangeComplete', this.trackPageview)
  }

  componentWillUnmount() {
    Router.router.events.off('routeChangeComplete', this.trackPageview)
  }

  trackPageview = (path: string = document.location.pathname) => {
    if (ym && prodStage && path !== this.lastTrackedPath) {
      ym('hit', path)
      this.lastTrackedPath = path
    }
  }

  render() {
    const { country } = this.context
    const accountId = YANDEX_METRIKA_ID[country]

    return prodStage ? (
      <YMInitializer
        accounts={[accountId]}
        options={{ webvisor: true }}
        version="2"
      />
    ) : null
  }
}
