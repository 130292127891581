// @flow
import * as React from 'react'
import ReactGA from 'react-ga'
import Router from 'next/router'
import getConfig from 'next/config'
import hoistNonReactStatic from 'hoist-non-react-statics'

import { CountryContext } from 'lib/withCountry'

import { GOOGLE_ANALYTICS_ID } from '../constants'

const {
  publicRuntimeConfig: { upStage }
} = getConfig()

const debug = process.env.NODE_ENV !== 'production'
const prodStage = ['production', 'prod'].includes(upStage)

type Props = Object

export default function AnalyticsHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  class AnalyticsHOC extends React.Component<Props> {
    lastTrackedPath: ?string

    static contextType = CountryContext

    componentDidMount() {
      this.initGa()
      this.trackPageview()
      Router.router.events.on('routeChangeComplete', this.trackPageview)
    }

    componentWillUnmount() {
      Router.router.events.off('routeChangeComplete', this.trackPageview)
    }

    trackPageview = (path: string = document.location.pathname) => {
      if ((debug || prodStage) && path !== this.lastTrackedPath) {
        ReactGA.pageview(path)
        this.lastTrackedPath = path
      }
    }

    initGa() {
      if (!window.GA_INITIALIZED) {
        const { country } = this.context
        const trackerId = GOOGLE_ANALYTICS_ID[country]

        ReactGA.initialize(trackerId, { debug })
        window.GA_INITIALIZED = true
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  hoistNonReactStatic(AnalyticsHOC, WrappedComponent)
  return AnalyticsHOC
}
