// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import { useRouter } from 'next/router'
import hoistNonReactStatics from 'hoist-non-react-statics'
import Cookies from 'js-cookie'
import pick from 'lodash/pick'
import useSWR from 'swr'
import * as Sentry from '@sentry/nextjs'

import { type UserType, getUserData } from 'utils/api'
import * as store from 'utils/store'
import { manageSourceParameter } from 'utils/analytics'
import * as Analytics from 'utils/analytics'

import { useCountry } from './withCountry'

import { YANDEX_METRIKA_ID } from '../constants'

const AUTH_COOKIE_EXPIRY_DAYS = 365

export const USER_DATA_KEYS = [
  'id',
  'firstName',
  'lastName',
  'email',
  'phone',
  'address',
  'tin',
  'passportFullname',
  'systemNumber',
  'warehouseId',
  'balance',
  'hasOrders',
  'regionId',
]

export type AuthContextValue = {
  isRehydrating: boolean,
  isRehydrated: boolean,
  token: ?string,
  userData: ?UserType,
  setUserData: (userData: UserType) => void,
  refreshUserData: () => Promise<*>,
  login: (
    token: string,
    userData: UserType,
    rememberMe?: boolean
  ) => Promise<*>,
  logout: () => void,
}

const defaultValue: AuthContextValue = {
  isRehydrating: false,
  isRehydrated: false,
  token: null,
  userData: null,
  setUserData: () => {},
  refreshUserData: async () => {},
  login: async () => {},
  logout: () => {},
}

const AuthContext: React.Context<AuthContextValue> = React.createContext(
  defaultValue
)

export default AuthContext

type Props = {
  authToken: ?string,
}

export function withAuthContext(WrappedComponent: React.ComponentType<*>) {
  function authContextHOC(props: Props) {
    const router = useRouter()
    const { country } = useCountry()
    const [isRehydrating, setRehydratingState] = React.useState(true)
    const [isRehydrated, setRehydratedState] = React.useState(false)
    const [token, setToken] = React.useState(props.authToken)
    const [userData, setUserData] = React.useState(undefined)

    React.useEffect(() => {
      if (userData && typeof userData.id === 'number') {
        const yandexAccountId = YANDEX_METRIKA_ID[country]
        Analytics.setUserId(userData.id, yandexAccountId)

        Sentry.configureScope(scope => {
          scope.setUser({ id: userData.id })
        })
      }
    }, [userData, country])

    React.useEffect(() => {
      async function rehydrate() {
        try {
          const nextUserData = await store.get('authUserData')
          setUserData(nextUserData)
        } catch (error) {
          console.log(
            '[AuthContextHOC] Error while rehydrating user data',
            error
          )
        } finally {
          setRehydratedState(true)
          setRehydratingState(false)
        }
      }

      rehydrate()
    }, [])

    React.useEffect(() => {
      if (isRehydrated) {
        const { utm_source } = router.query

        if (typeof utm_source === 'string') {
          manageSourceParameter(utm_source, token)
        }
      }
    }, [isRehydrated, router.query, token])

    const handleSetUserData = React.useCallback(function (
      nextUserData: ?UserType
    ) {
      // $FlowFixMe
      setUserData(nextUserData)
      store.set('authUserData', nextUserData)
    },
    [])

    const refreshUserData = React.useCallback(
      async function refreshUserData() {
        if (!token) throw new Error('Unauthorized')

        if (!userData)
          throw new Error('To refresh userData you have to have one already')

        const { data } = await getUserData(token, userData.id)
        const nextUserData = pick(data, USER_DATA_KEYS)
        handleSetUserData(nextUserData)
      },
      [token, userData, handleSetUserData]
    )

    const handleLogin = React.useCallback(
      async function (
        nextToken: string,
        nextUserData: UserType,
        rememberMe?: boolean
      ) {
        setToken(nextToken)
        setUserData(nextUserData)

        await store.set('authUserData', nextUserData)
        Cookies.set('authToken', nextToken, {
          expires: rememberMe ? AUTH_COOKIE_EXPIRY_DAYS : undefined,
          sameSite: 'Lax',
          secure: true
        })
        const authRedirectedFrom = await store.get('authRedirectedFrom')

        if (authRedirectedFrom) {
          let from
          
          store.clear('authRedirectedFrom')

          try {
            from = new URL(authRedirectedFrom, self.location)
            return router.replace({ pathname: from?.pathname })
          } catch (error) {
            console.error(error)
          }
        }

        router.replace(nextUserData.hasOrders ? '/parcels' : '/address')
      },
      [router]
    )

    const handleLogout = React.useCallback(
      function () {
        setToken(null)
        setUserData(null)

        router.push('/')

        store.clear('authToken')
        store.clear('authUserData')
        Cookies.remove('authToken')
      },
      [router]
    )

    const contextValue = React.useMemo(
      () => ({
        isRehydrating,
        isRehydrated,
        token,
        userData,
        setUserData: handleSetUserData,
        refreshUserData,
        login: handleLogin,
        logout: handleLogout,
      }),
      [
        isRehydrating,
        isRehydrated,
        token,
        userData,
        handleSetUserData,
        refreshUserData,
        handleLogin,
        handleLogout,
      ]
    )

    return (
      // $FlowFixMe
      <AuthContext.Provider value={contextValue}>
        <WrappedComponent authToken={token} {...props} />
      </AuthContext.Provider>
    )
  }

  hoistNonReactStatics(authContextHOC, WrappedComponent)
  return authContextHOC
}

export function useLatestUserData() {
  const authContext = React.useContext(AuthContext)
  const userId = authContext?.userData?.id
  const { data: nextUserData } = useSWR(userId ? `users/${userId}` : null)

  React.useEffect(() => {
    if (nextUserData) {
      authContext.setUserData(nextUserData)
    }
  }, [nextUserData])

  return authContext?.userData ?? nextUserData
}
