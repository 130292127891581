// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { Router } from 'wouter'
import { useRouter } from 'next/router'

import staticLocationHook from 'wouter/static-location'

export default function WouterHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function wouterHOC(props: Object) {
    const { asPath } = useRouter()
    return (
      <Router
        hook={
          typeof window === 'undefined' ? staticLocationHook(asPath) : undefined
        }
      >
        <WrappedComponent {...props} />
      </Router>
    )
  }

  hoistNonReactStatics(wouterHOC, WrappedComponent)
  return wouterHOC
}
