export default {
  ru: {
    userSettingsPage: {
      title: 'Настройки аккаунта'
    },
    userSettingsForm: {
      personalData: {
        title: 'Личные данные',
        firstName: 'Имя',
        lastName: 'Фамилия',
        phone: 'Телефон',
        email: 'E-mail'
      },
      password: {
        title: 'Изменить пароль',
        newPassword: 'Новый пароль',
        newPasswordConfirmation: 'Повторите новый пароль'
      },
      recepient: {
        title: 'Получатель',
        tin: 'ИИН',
        passportFullname: 'ФИО',
      },
      address: {
        title: 'Адрес',
        homeAddress: 'Ваш адрес в {countryName}'
      },
      submit: 'Сохранить',
      submitting: 'Отправка...'
    }
  }
}
