const MobileDetect = require('mobile-detect')

module.exports = (headers = {}) => {
  const ua = headers['user-agent'] || headers['User-Agent']
  const md = new MobileDetect(ua)

  return {
    phone: !!md.phone(),
    tablet: !!md.tablet(),
    mobile: !!md.mobile(),
    desktop: !md.mobile()
  }
}
