// @flow
const NO_UNDERLYING_API_ERROR_MESSAGE =
  'There is no localStorage available in this environment'

export async function get(key: string): Promise<?any> {
  let value = null

  if (typeof window !== 'undefined') {
    await null
    const savedValue = window.localStorage.getItem(key)

    if (typeof savedValue === 'string') {
      try {
        value = JSON.parse(savedValue)
      } catch (error) {
        // It's fine. Looks like there was no value stored
      }
    }
  }

  return value
}

export async function set(key: string, value: any): Promise<void> {
  if (typeof window !== 'undefined') {
    try {
      await null
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(`Error setting '${key}' with '${value}'`, error)
    }
  } else {
    throw new Error(NO_UNDERLYING_API_ERROR_MESSAGE)
  }
}

export async function clear(key: string): Promise<void> {
  if (typeof window !== 'undefined') {
    await null
    window.localStorage.removeItem(key)
  } else {
    throw new Error(NO_UNDERLYING_API_ERROR_MESSAGE)
  }
}

export async function clearAll(): Promise<void> {
  if (typeof window !== 'undefined') {
    await null
    window.localStorage.clear()
  } else {
    throw new Error(NO_UNDERLYING_API_ERROR_MESSAGE)
  }
}
