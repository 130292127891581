// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import * as BX24LiveChat from 'utils/bx24livechat'
import { generateRandomUUIDString } from 'utils/misc'

export type ModalContentProps = {
  open: (renderContent: ContentRenderer) => void,
  close: () => void,
}

type ContentRenderer = React.ComponentType<*>

type ContextValue = {
  hasModals: boolean,
  open: (renderContent: ContentRenderer) => void,
  close: () => void,
}

const defaultContextValue: ContextValue = {
  hasModals: false,
  open: () => {},
  close: () => {},
}

export const ModalStackContext = React.createContext<ContextValue>(
  defaultContextValue
)

type Props = Object

export default function ModalStackContextHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function modalStackContextHOC(props: Props) {
    const topmostModalRef = React.useRef()
    const [modalStack, setModalStack] = React.useState([])
    const hasModals = modalStack.length > 0

    React.useEffect(() => {
      if (hasModals) {
        if (topmostModalRef.current) {
          disableBodyScroll(topmostModalRef.current)
        }
          
        BX24LiveChat.hideButton()
      } else {
        clearAllBodyScrollLocks()
        BX24LiveChat.showButton()
      }

      return function cleanup() {
        clearAllBodyScrollLocks()
      }
    }, [hasModals])

    const handleOpen = React.useCallback(
      (renderContent: ContentRenderer) => {
        setModalStack([
          ...modalStack,
          { id: generateRandomUUIDString(), renderContent },
        ])
      },
      [modalStack]
    )

    const handleClose = React.useCallback(() => {
      const nextModalStack = [...modalStack]

      enableBodyScroll(topmostModalRef.current)

      nextModalStack.pop()
      setModalStack(nextModalStack)
    }, [modalStack])

    const contextValue = React.useMemo(
      () => ({ hasModals, open: handleOpen, close: handleClose }),
      [modalStack, handleOpen, handleClose]
    )

    return (
      <>
        <ModalStackContext.Provider value={contextValue}>
          <WrappedComponent {...props} />
        </ModalStackContext.Provider>
        {hasModals ? (
          <div className="modal-stack">
            {modalStack.map(
              ({ renderContent: ContentRendererComponent, id }, index) => (
                <ContentRendererComponent
                  key={id}
                  ref={modalStack.length - 1 === index ? topmostModalRef : undefined}
                  open={handleOpen}
                  close={handleClose}
                />
              )
            )}
          </div>
        ) : null}
      </>
    )
  }

  hoistNonReactStatics(modalStackContextHOC, WrappedComponent)
  return modalStackContextHOC
}

export function useModalStack() {
  const { hasModals, open, close } = React.useContext(ModalStackContext)

  return { hasModals, openModal: open, closeModal: close }
}
