// @flow
import getPath from 'lodash/get'

type BX24ButtonType = {
  show: Function,
  hide: Function,
}

function getButtonInstance(): ?BX24ButtonType {
  return getPath(window, 'bx24.buttonInstance', undefined)
}

export function hideButton() {
  if (typeof window !== 'undefined') {
    const buttonInstance = getButtonInstance()
    if (buttonInstance) {
      buttonInstance.hide()
    }
  }
}

export function showButton() {
  if (typeof window !== 'undefined') {
    const buttonInstance = getButtonInstance()

    if (buttonInstance) {
      buttonInstance.show()
    }
  }
}
