// @flow
/* eslint react-hooks/rules-of-hooks: 0 */
import * as React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { Context as ResponsiveContext } from 'react-responsive'
import throttle from 'lodash/throttle'

const RESIZE_THROTTLE = 150

type Props = {
  defaultWindowSize: number
}

export default function ResponsiveContextHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  function responsiveContextHOC(props: Props) {
    const [viewportWidth, setViewportWidth] = React.useState(
      props.defaultWindowSize
    )
    const throttledCallback = React.useRef(
      throttle(() => setViewportWidth(window.innerWidth), RESIZE_THROTTLE)
    )

    React.useEffect(() => {
      window.addEventListener('resize', throttledCallback.current, {
        passive: true
      })

      throttledCallback.current()

      return function cleanup() {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.removeEventListener('resize', throttledCallback.current)
      }
    }, [])

    return (
      <ResponsiveContext.Provider value={{ width: viewportWidth }}>
        <WrappedComponent {...props} />
      </ResponsiveContext.Provider>
    )
  }

  hoistNonReactStatics(responsiveContextHOC, WrappedComponent)
  return responsiveContextHOC
}
