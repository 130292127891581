// @flow
import * as React from 'react'
import cn from 'clsx'
import hoistNonReactStatic from 'hoist-non-react-statics'

import { transition } from '../utils/style'
import * as BX24LiveChat from '../utils/bx24livechat'

type ContentRenderer = (props: ModalContentProps) => React.Node

export type ModalContentProps = {
  open: (renderContent: ContentRenderer) => void,
  close: () => void,
}

type ModalContextValue = {
  isVisible: boolean,
  open: (renderContent: ContentRenderer) => void,
  close: () => void,
}

const defaultContextValue: ModalContextValue = {
  isVisible: false,
  open: () => {},
  close: () => {},
}

export const ModalContext: React.Context<ModalContextValue> = React.createContext(
  defaultContextValue
)

type Props = Object

type State = ModalContextValue

const defaultContentRenderer: ContentRenderer = () => null

export default function ModalHOCWrapper(
  WrappedComponent: React.ComponentType<*>
) {
  class ModalHOC extends React.Component<Props, State> {
    contentRenderer: ContentRenderer = defaultContentRenderer

    constructor(props: Props) {
      super(props)

      this.contentRenderer = defaultContentRenderer

      this.state = {
        isVisible: false,
        open: this.handleOpen,
        close: this.handleClose,
      }
    }

    componentDidMount() {
      document.addEventListener('keydown', this.handleKeyPress, false)
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.handleKeyPress, false)
    }

    handleKeyPress = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        this.handleClose()
      }
    }

    handleOverlayPress = () => {
      const { isVisible } = this.state

      if (isVisible) {
        this.handleClose()
      }
    }

    handleOpen = (contentRenderer: ContentRenderer) => {
      this.contentRenderer = contentRenderer

      this.setState(
        {
          isVisible: true,
        },
        () => {
          this.forceUpdate()

          BX24LiveChat.hideButton()
        }
      )
    }

    handleClose = () => {
      this.setState(
        {
          isVisible: false,
        },
        () => {
          this.contentRenderer = defaultContentRenderer

          BX24LiveChat.showButton()
        }
      )
    }

    render() {
      const { isVisible } = this.state
      const ContentRendererComponent = this.contentRenderer

      return (
        <React.Fragment>
          <div
            onClick={this.handleOverlayPress}
            className={cn('wrapper', { 'modal-visible': isVisible })}
          >
            <ModalContext.Provider value={this.state}>
              <WrappedComponent {...this.props} />
            </ModalContext.Provider>
          </div>
          {isVisible ? (
            <div className="modal">
              <ContentRendererComponent
                open={this.handleOpen}
                close={this.handleClose}
              />
            </div>
          ) : null}

          <style jsx>{`
            .wrapper {
              transition: filter 0.3s ${transition.timingFunction};
            }

            .modal-visible {
              filter: blur(5px);
            }

            .modal {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 100;

              display: flex;

              pointer-events: none;
            }
          `}</style>
        </React.Fragment>
      )
    }
  }

  hoistNonReactStatic(ModalHOC, WrappedComponent)
  return ModalHOC
}
