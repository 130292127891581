import * as React from 'react'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import clamp from 'lodash/clamp'

import { colors } from '../utils/style'

type Props = {
  items: Array<{ src: string, label: string }>,
  itemWidth?: string | number,
  index: number,
  setPhotoIndex: Function,
  onClose: Function,
}

export default function LightBox({
  items,
  itemWidth = 'full',
  index,
  setPhotoIndex,
  onClose,
}: Props) {
  const windowWidth = document?.documentElement?.clientWidth ?? document?.body?.clientWidth
  let width = itemWidth === 'full' ? windowWidth : itemWidth

  const idx = React.useCallback(
    (x, l = items.length) => {
      return (x + l) % l
    },
    [items]
  )
  // Important only if specifyng width, centers the element in the slider
  const offset = itemWidth === 'full' ? 0 : (windowWidth - itemWidth) / 2

  // Defines currently visible slides
  const firstVis = idx(index)

  const setHandler = React.useCallback(
    ({ i, down, xMove }) => {
      if (items.length === 1)
        return {
          x: 0,
          immediate: true,
        }
      const position = (i - firstVis) * width

      return {
        // x is the position of each of our slides
        x: position + (down ? xMove : 0) + offset,
        immediate: false,
      }
    },
    [items.length, firstVis, width, offset]
  )

  const [springs, set] = useSprings(items.length, (i) => setHandler({ i }))

  const runSprings = React.useCallback(
    (y, vy, down, xDir, cancel, distance, xMove) => {
      // This decides if we move over to the next slide or back to the initial

      if (down && distance > width / 2) {
        cancel(setPhotoIndex(clamp(index + (xDir > 0 ? -1 : 1), 0, items.length - 1)))
      }

      set((i) => setHandler({ i, down, xMove }))
    },
    [width, set, setPhotoIndex, index, setHandler, items.length]
  )

  const bind = useDrag(
    ({
      offset: [x],
      vxvy: [vx],
      down,
      direction: [xDir],
      cancel,
      distance,
      movement: [xMove],
    }) => {
      vx && runSprings(-x, -vx, down, xDir, cancel, distance, xMove)
    }
  )

  return (
    <div className="lightbox">
      <div className="item-wrapper">
        <div className="title">
          <div className="label">{items[idx(index)].label}</div>
          <button type="button" className="close-button" onClick={onClose}>
            <svg width="24" height="24">
              <use xlinkHref="#close" />
            </svg>
          </button>
        </div>
        <animated.div className="image-wrapper" {...bind()}>
          {springs.map(({ x }, i) => (
            <animated.div
              key={i}
              className="image"
              style={{
                backgroundImage: `url(${items[i].src})`,
                width,
                transform: x.interpolate((x) => `translate3d(${x}px,0,0)`),
              }}
            />
          ))}
        </animated.div>
      </div>

      <style jsx>{`
        .lightbox {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10000;
          background: rgba(0, 0, 0, 0.8);
        }
        .lightbox :global(.image-wrapper) {
          width: 100vw;
          height: 100vh;
          position: absolute;
          will-change: transform;
          background: black;
        }
        .lightbox :global(.image) {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          width: 100%;
          height: 100%;
          will-change: transform;
          position: absolute;
          top: 0;
          left: 0;
        }
        .image {
          width: 100%;
          height: auto;
        }
        .title {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 11000;

          display: flex;
          align-items: center;
          width: 100%;
          padding: 20px;

          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        .label {
          font-size: 24px;
          font-weight: bold;

          color: ${colors.white};
        }

        .close-button {
          display: inline-flex;
          padding: 0;
          margin-left: auto;
          border: none;

          background: transparent;
          color: ${colors.white};

          cursor: pointer;
        }
      `}</style>
    </div>
  )
}
